import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { FormInputConfig } from '~/types/residentManagement';
import { FormField } from '~/components/FormField';
import { IReferralSettingForm, LeadPMSTypes } from './interfaces';
import { toast } from 'react-toastify';
import Spinner from '~/components/Spinner/Spinner';
import useReferralSetting from '~/hooks/building/referral/useReferralSetting';
import { numberRegex } from '~/helpers/helpers';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import './ReferralSetting.css';
import MultiSelectCheckboxList from '~/components/MultiSelectCheckBoxList/MultiSelectCheckBoxList';
import { IMultiSelectOptionList, ISelectOption } from '~/helpers/interfaces';
import {
  fetchYardiPropertyAgentsAndSources,
  fetchYardiProperties,
  YardiProperty
} from '~/api/yardiIntegration';
import { withFullScreenLoader } from '~/hocs/withFullScreenLoader';
import { useFullScreenLoader } from '~/hooks/useFullScreenLoader';
import { load } from 'dotenv';

const ReferralSettingComponent = () => {
  const { buildingId } = useParams<{ buildingId: string }>();
  const { loader } = useFullScreenLoader();
  const {
    loader: loadingBuildingConfig,
    postCommunitySetting,
    postEntrataPMSSetting,
    postYardiPMSSetting,
    postCustomEmails,
    buildingConfig,
    buildingData,
    entrataIntegrations,
    entrataCheckboxOptions,
    setEntrataCheckboxOptions,
    yardiIntegrations = [],
    externalEmail
  } = useReferralSetting(buildingId);

  const history = useHistory();

  const entrataUuid = useRef('');
  const yardiaUuid = useRef('');

  const [agents, setAgents] = useState<ISelectOption[]>([]);
  const [sources, setSources] = useState<ISelectOption[]>([]);
  const [yardiProperties, setyardiProperties] = useState<YardiProperty[]>([]);

  const {
    register,
    handleSubmit,
    errors,
    control,
    watch,
    getValues,
    reset,
    setValue,
    formState: { dirtyFields, isDirty }
  } = useForm<IReferralSettingForm>({
    defaultValues: {
      communityLead: false,
      communityLeadStart: '',
      defaultCobuPayout: '',
      communityLeadsTandC: ''
    }
  });

  const yardi_property_id = watch('yardi_property_id');

  const getYardiProperties = () => {
    fetchYardiProperties().then((res) =>
      setyardiProperties(res.data?.properties)
    );
  };

  const getYardiPropertyAgentAndSources = async () => {
    const selectedProperty = yardiProperties.find(
      (prop) => prop.Code == yardi_property_id
    );

    if (!selectedProperty?.credentialsId) return;

    loader.show();

    fetchYardiPropertyAgentsAndSources({
      propertyId: yardi_property_id!,
      credentials: selectedProperty?.credentialsId
    })
      .then((res) => {
        setAgents(
          res.data?.agents?.map((agent) => ({ label: agent, value: agent }))
        );
        setSources(
          res.data?.sources?.map((source) => ({ label: source, value: source }))
        );
      })
      .finally(() => {
        loader.hide();
      });
  };

  useEffect(() => {
    if (
      buildingData &&
      buildingConfig &&
      entrataIntegrations &&
      yardiIntegrations &&
      externalEmail
    ) {
      const communityLead = buildingConfig?.config?.communityLead ?? false;
      const communityLeadStart =
        buildingConfig?.config?.communityLeadStart ?? '';
      const defaultCobuPayout = buildingConfig?.config?.defaultCobuPayout ?? '';
      const communityLeadsTandC =
        buildingConfig?.config?.communityLeadsTandC ?? '';
      const propertyWebsiteURL = buildingData?.propertyWebsiteURL;

      const defaultEntrataIntegration = entrataIntegrations?.find(
        (config) => config.default
      );

      const leadPMS =
        buildingConfig?.config?.leadPMSOpted ||
        (defaultEntrataIntegration ? LeadPMSTypes.entrata : undefined);

      const defaultYardiIntegration = yardiIntegrations?.[0];

      entrataUuid.current = defaultEntrataIntegration?.uuid || '';
      yardiaUuid.current = defaultYardiIntegration?.uuid || '';

      let customEmails = '';

      if (externalEmail) {
        customEmails = externalEmail.email;
      }

      reset({
        communityLead,
        communityLeadStart,
        defaultCobuPayout,
        communityLeadsTandC,
        propertyWebsiteURL,
        leadPMSOpted: leadPMS,
        agentName: defaultYardiIntegration?.details?.agentName,
        leadSourceName: defaultYardiIntegration?.details?.leadSourceName,
        entrata_domain: defaultEntrataIntegration?.entrata_domain || '',
        entrata_property_id:
          defaultEntrataIntegration?.entrata_property_id || '',
        entrata_username: defaultEntrataIntegration?.entrata_username || '',
        entrata_password: defaultEntrataIntegration?.entrata_password || '',
        entrataLeadSourceId: defaultEntrataIntegration?.leadSourceId || '',

        yardi_property_id:
          yardi_property_id ??
          (defaultYardiIntegration?.yardi_property_id || ''),

        directEmails: customEmails
      });
    }
  }, [
    buildingConfig,
    buildingData,
    entrataIntegrations,
    externalEmail,
    agents,
    sources
  ]);

  const referralEnabled = watch('communityLead');

  const leadPMSOpted = watch('leadPMSOpted');
  const enableDirectEmails = leadPMSOpted === LeadPMSTypes.email;
  const enableEntrataLead = leadPMSOpted === LeadPMSTypes.entrata;
  const enableYardiLead = leadPMSOpted === LeadPMSTypes.yardi;

  const generateField = (fieldConfig: FormInputConfig, className?: string) => {
    return (
      <div key={fieldConfig.id} className={className}>
        <div className='item'>
          {!!fieldConfig?.label && (
            <label
              style={fieldConfig?.styles?.lableStyles}
              className={`${fieldConfig?.styles?.lableClassName}`}
              htmlFor='title'
            >
              {fieldConfig.label}
            </label>
          )}
          <FormField
            fieldConfig={fieldConfig}
            errors={errors}
            register={register}
            control={control}
          />
        </div>
      </div>
    );
  };

  const onSubmit = async (settings: IReferralSettingForm) => {
    if (!isDirty) {
      toast('Please Edit the form');
      return;
    }

    const {
      entrata_domain,
      entrata_property_id,
      entrata_username,
      entrata_password,
      entrataLeadSourceId,
      yardi_property_id,
      directEmails,
      ...referralSetting
    } = settings;

    try {
      if (enableEntrataLead) {
        await postEntrataPMSSetting(buildingId, {
          entrata_domain,
          entrataUuid: entrataUuid.current,
          entrata_property_id,
          entrata_username,
          entrata_password,
          entrataLeadSourceId
        });
      } else if (enableYardiLead) {
        const selectedProperty = yardiProperties.find(
          (prop) => prop.Code == yardi_property_id
        );

        await postYardiPMSSetting({
          buildingId,
          agentName: getValues('agentName'),
          leadSourceName: getValues('leadSourceName'),
          yardiVersion: selectedProperty?.yardiVersion,
          credentials: selectedProperty?.credentialsId,
          settings: {
            yardiUuid: yardiaUuid.current,
            yardi_property_id
          }
        });
      } else if (enableDirectEmails && directEmails) {
        await postCustomEmails(buildingId, directEmails.trim() as string);
      }

      await postCommunitySetting(buildingId, referralSetting);

      toast('Referral details are saved');

      history.goBack();
    } catch (e) {
      toast('Error saving referral details');
    }
  };

  const referrelSettingFormConfig: FormInputConfig[] = [
    {
      label: 'Enable Community lead',
      inputType: 'switch',
      id: 'communityLead'
    },
    ...((referralEnabled
      ? [
          {
            label: 'Community start date',
            inputType: 'date',
            id: 'communityLeadStart'
          },
          {
            label: 'Default cobu payout',
            inputType: 'text',
            id: 'defaultCobuPayout',
            pattern: numberRegex,
            patternErrorMsg: 'Please enter a valid number'
          },
          {
            label: 'Community T&C',
            inputType: 'textarea',
            id: 'communityLeadsTandC',
            isRequired: true
          },
          {
            label: 'Property Website URL',
            inputType: 'text',
            id: 'propertyWebsiteURL',
            isRequired: false
          }
        ]
      : []) as FormInputConfig[])
  ];

  const entrataPMSFormConfig: FormInputConfig[] = [
    {
      label: 'Domain',
      inputType: 'text',
      id: 'entrata_domain',
      isRequired: true
    },
    {
      label: 'Property ID',
      inputType: 'text',
      id: 'entrata_property_id',
      isRequired: true
    },
    {
      label: 'Username',
      inputType: 'text',
      id: 'entrata_username',
      isRequired: true
    },
    {
      label: 'Password',
      inputType: 'text',
      id: 'entrata_password',
      isRequired: true
    },
    {
      label: 'Lead source ID',
      inputType: 'text',
      id: 'entrataLeadSourceId',
      isRequired: true
    }
  ];

  const yardiPMSFormConfig: FormInputConfig[] = [
    {
      label: 'Property ID *',
      inputType: 'select',
      selectOptions:
        yardiProperties?.map((property) => ({
          label: property.MarketingName,
          value: property.Code
        })) ?? [],
      id: 'yardi_property_id',
      isRequired: true
    },
    {
      label: 'Agent Name *',
      inputType: 'select',
      id: 'agentName',
      isRequired: true,
      selectOptions: agents
    },
    {
      label: 'Source',
      inputType: 'select',
      id: 'leadSourceName',
      isRequired: false,
      selectOptions: sources
    }
  ];

  const directEmailsConfig: FormInputConfig[] = [
    {
      label: 'Direct emails',
      placeholder: 'please add comma separated external emails',
      inputType: 'textarea',
      id: 'directEmails',
      isRequired: true
    }
  ];

  const switchCofig: FormInputConfig[] = [
    {
      label: 'Lead PMS integration',
      inputType: 'select',
      isRequired: false,
      id: 'leadPMSOpted',
      selectOptions: [
        { label: 'Entrata', value: LeadPMSTypes.entrata },
        { label: 'Yardi', value: LeadPMSTypes.yardi },
        { label: 'Email', value: LeadPMSTypes.email }
      ]
    }
  ];

  const selectedPMIntegrationForm = (() => {
    if (enableDirectEmails) {
      return (
        <div>{directEmailsConfig.map((field) => generateField(field))}</div>
      );
    } else if (enableEntrataLead) {
      return (
        <>
          {/* <div className='table-separater' /> */}
          <div className='referral-settting-grid'>
            {entrataPMSFormConfig.map((field, index) =>
              generateField(field, `entrata-item-${index}`)
            )}
            {entrataCheckboxOptions && entrataCheckboxOptions.length && (
              <div className='item'>
                <label style={{ margin: '0 0 8px 0' }} htmlFor='title'>
                  {'Available Integrations, check to mark default'}
                </label>
                <MultiSelectCheckboxList
                  options={entrataCheckboxOptions}
                  setSelectedOption={(value, isSelected) => {
                    onEntrataCheckBox(value, isSelected);
                  }}
                />
              </div>
            )}
          </div>
        </>
      );
    } else if (enableYardiLead) {
      return (
        <>
          {/* <div className='table-separater' /> */}
          <div>{yardiPMSFormConfig.map((field) => generateField(field))}</div>
        </>
      );
    }

    return null;
  })();

  const onEntrataCheckBox = (value: string, isSelected: boolean) => {
    const newBoardingOptions = [
      ...(entrataCheckboxOptions as IMultiSelectOptionList[])
    ].map((checkboxOption) => {
      if (checkboxOption.value === value) {
        return { ...checkboxOption, isSelected };
      }

      return { ...checkboxOption, isSelected: false };
    });

    const newSelectedIntegration = entrataIntegrations?.find(
      (integration) => integration.uuid === value
    );

    entrataUuid.current = isSelected ? newSelectedIntegration?.uuid || '' : '';

    setValue(
      'entrata_domain',
      isSelected ? newSelectedIntegration?.entrata_domain : '',
      { shouldDirty: true }
    );
    setValue(
      'entrata_property_id',
      isSelected ? newSelectedIntegration?.entrata_property_id : '',
      { shouldDirty: true }
    );
    setValue(
      'entrata_username',
      isSelected ? newSelectedIntegration?.entrata_username : '',
      { shouldDirty: true }
    );
    setValue(
      'entrata_password',
      isSelected ? newSelectedIntegration?.entrata_password : '',
      { shouldDirty: true }
    );
    setValue(
      'entrataLeadSourceId',
      isSelected ? newSelectedIntegration?.leadSourceId : '',
      { shouldDirty: true }
    );

    setEntrataCheckboxOptions(newBoardingOptions);
  };

  useEffect(() => {
    getYardiPropertyAgentAndSources();
  }, [yardi_property_id]);

  useEffect(() => {
    getYardiProperties();
  }, [enableYardiLead]);

  useEffect(() => {
    if (loadingBuildingConfig) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [loadingBuildingConfig]);

  return (
    <div
      className='container'
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
      }}
    >
      <Breadcrumbs />
      <div className='float-spinner-parent'>
        <form
          className='margin-top-24'
          id='form-container'
          onSubmit={handleSubmit(onSubmit)}
        >
          <div style={{ flexDirection: 'row', display: 'flex', gap: '0 30px' }}>
            <div>
              {referrelSettingFormConfig.map((field) => generateField(field))}
            </div>
            {referralEnabled && (
              <>
                <div className='table-separater' />
                <div>{switchCofig.map((field) => generateField(field))}</div>
                {selectedPMIntegrationForm}
              </>
            )}
          </div>
          <button
            className='btn-standard btn-primary margin-top-24'
            type='submit'
            disabled={!isDirty}
          >
            {'Save'}
          </button>
        </form>
      </div>
    </div>
  );
};

export const ReferralSetting = withFullScreenLoader(ReferralSettingComponent);
